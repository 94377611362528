<template>
  <div class="video__holder" ref="holder">
    <video 
      ref="video" 
      class="video"
      @play="onPlay"
      @pause="onPause"
      @timeupdate="onTimeUpdate"
      :autoplay="autoplay"
      @loadedmetadata="onMetadataLoaded"
      @waiting="onWaiting"
      @click="(isPlaying || paused) && togglePlay()"
      disableRemotePlayback
      :poster="poster"
    >
      <source :src="source" :type="mime">
      <p>{{ nonSupportedText }}</p>
      <slot></slot>
    </video>
    <div class="video__playbtn" v-show="!isPlaying || isWaiting" @click="togglePlay">
      <Icon name="svg-spinners:ring-resize" v-if="isWaiting"/>
      <Icon name="mdi:play" v-else/>
    </div>
    <div class="video__controls" v-show="(isPlaying || paused) && isHovered">
      <button class="video__controlbtn" @click="togglePlay">
        <Icon name="mdi:play" v-if="!isPlaying"/>
        <Icon name="mdi:pause" v-else/>
      </button>
      <div 
        class="video__progress"
        ref="progressBar"
        @click="scrub"
        @mousemove="isManipulatingTime && scrub($event)"
      >
        <div class="progress__filled" :style="`width: ${progress}%`"></div>
      </div>
      <button class="video__controlbtn" @click="toggle">
        <Icon name="mdi:fullscreen" v-if="!isFullscreen"/>
        <Icon name="mdi:fullscreen-exit" v-else/>
      </button>
      <div class="volume__holder">
        <button class="video__controlbtn" @click="toggleMute">
          <Icon name="mdi:volume-high" v-if="!isMuted"/>
          <Icon name="mdi:volume-off" v-else/>
        </button>
        <div class="volume__control">
          <Slider
            class="volume__slider"
            v-model="volume"
            :min="0"
            :max="100"
            :step="1"
            orientation="vertical"
            direction="rtl"
            showTooltip="drag"
            @change="changeVolume"
          /> 
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Slider from '@vueform/slider';
import '@vueform/slider/themes/default.css';

// TODO: maybe allow multiple?
const props = defineProps({
  source: {
    type: String as PropType<string>,
    required: true
  },
  mime: {
    type: String as PropType<string>,
    default: 'video/mp4'
  },
  poster: {
    type: String as PropType<string>,
    default: null
  },
  nonSupportedText: {
    type: String as PropType<string>,
    default: 'Uw browser ondersteunt geen HTML5 video\'s.'
  },
  autoplay: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  initialVolume: {
    type: Number as PropType<number>,
    default: 100
  }
});

const video = ref<HTMLVideoElement | null>(null);
const holder = ref<HTMLDivElement | null>(null);
const progressBar = ref<HTMLDivElement | null>(null);

const paused = ref<boolean>(false); // Needs to be initialized to false
const timeElapsed = ref<number>(0);
const duration = ref<number>(0);
const isPlaying = ref<boolean>(false);
const volume = ref<number>(props.initialVolume);
const isMuted = ref<boolean>(false);
const isWaiting = ref<boolean>(false);

const progress = computed(() => ((timeElapsed.value / duration.value) * 100) || 0);

const { isFullscreen, toggle } = useFullscreen(video);
const isHovered = useElementHover(holder)
const { pressed: isManipulatingTime } = useMousePressed({ target: progressBar })

function onPlay() {
  isPlaying.value = true;
  isWaiting.value = false;
};

function onPause() {
  isPlaying.value = false;
  paused.value = true;
};

function onWaiting() {
  isWaiting.value = true;
}

function onTimeUpdate() {
  if (duration.value === 0) {
    duration.value = video.value?.duration || 0;
  }

  timeElapsed.value = video.value?.currentTime || 0;
};

function onMetadataLoaded() {
  duration.value = video.value?.duration || 0;
}

function togglePlay() {
  if (video.value?.paused) {
    video.value?.play();
  } else {
    video.value?.pause();
  }
}

function toggleMute() {
  if (video.value) {
    video.value.muted = !video.value.muted;
  }

  isMuted.value = !isMuted.value;
}

function changeVolume(value: number) {
  if (!video.value) return;

  video.value.volume = value / 100;
}

function scrub(e: MouseEvent) {
  if (!video.value || !e.currentTarget) return;

  const target = e.currentTarget as HTMLDivElement;
  const scrubTime = (e.offsetX / target.offsetWidth) * video.value.duration;

  video.value.currentTime = scrubTime;
}
</script>

<style lang="scss">
.video__holder {
  position: relative;
  height: auto;
  width: 100%;
}

.video {
  width: 100%;
  height: auto;
  position: relative;
  z-index: 0;
  padding: 0;
  margin: 0;
}

.video__controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0 0.5rem;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;

  .video__controlbtn {
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    flex-shrink: 0;
    flex-grow: 0;
    padding: 0.25rem 0.25rem;
  }

  .video__progress {
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    background: rgba(255, 255, 255, 0.5);
    height: 0.5rem;
    width: 100%;
    position: relative;

    .progress__filled {
      background: $secondary;
      height: 100%;
    }
  }
}

.video__playbtn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  cursor: pointer;
  height: 4.5rem;
  width: 4.5rem;
  border-radius: 50%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: all 365ms cubic-bezier(0, 0.55, 0.45, 1);

  svg {
    font-size: 3rem;
    color: $secondary;
  }

  &:hover {
    transform: translate(-50%, -50%) scale(1.08);
  }

  @include breakpoint(null, $sm) {
    height: 3.5rem;
    width: 3.5rem;

    svg {
      font-size: 2.5rem;
    }
  }
}

.volume__holder {
  gap: 0.5rem;
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;
  padding: 0.25rem 0;

  .volume__control {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.5);
    border-radius: 0.25rem 0.25rem 0 0;
    display: none;
    z-index: 1;
    justify-content: center;
    align-items: center;
    padding: 1rem 0.75rem;
  }

  &:hover .volume__control {
    display: flex;
  }

  .volume__slider {
    --slider-vertical-height: 5rem;
    --slider-bg: #{rgba(white, 0.3)};
    --slider-handle-bg: #{$secondary};
    --slider-handle-ring-width: 0px;
    --slider-tooltip-bg: #{$secondary};
    --slider-handle-shadow: none;
  }
}

video[poster] {
  object-fit: cover;
  width: 100%;
  height: auto;
}
</style>
